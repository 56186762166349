import React, { useState } from "react";

import useId from "@kikoff/hooks/src/useId";
import { combineClasses } from "@kikoff/utils/src/string";

import styles from "./underline_text_input.module.scss";

interface UnderlineTextInputProps extends React.HTMLProps<HTMLInputElement> {
  label: string;
}

const UnderlineTextInput: React.FC<UnderlineTextInputProps> = ({
  className,
  label,
  id: _id,
  ...props
}) => {
  // Id should not be changing
  const id = _id || useId();

  const [open, setOpen] = useState(!!props.defaultValue || !!props.value);
  const [focused, setFocused] = useState(false);

  return (
    <div
      className={combineClasses(
        styles["underline-text-input"],
        className,
        open && styles.open,
        focused && styles.focused
      )}
    >
      <input
        type="text"
        {...props}
        id={id}
        onChange={(e) => {
          if (!focused) setOpen(!!e.target.value);

          props.onChange?.(e);
        }}
        onFocus={(e) => {
          setOpen(true);
          setFocused(true);

          props.onFocus?.(e);
        }}
        onBlur={(e) => {
          if (e.target) setOpen(!!e.target.value);
          setFocused(false);

          props.onBlur?.(e);
        }}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default UnderlineTextInput;
